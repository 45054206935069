import React from 'react'
import { Container } from '@material-ui/core'

import styles from './styles.module.scss'

const NumberAndText = ({ about: { number, textOne, textTwo } }) => (
  <div className={styles.box__inner}>
    <div className={styles.box__inner__number}>{number}</div>
    <div>
      <h2>{textOne}</h2>
      <br />
      <h2>{textTwo}</h2>
    </div>
  </div>
)

const AboutUs = () => {
  const aboutArr = [
    {
      number: 9,
      textOne: 'лет',
      textTwo: 'работы',
    },
    {
      number: 73,
      textOne: 'частичных',
      textTwo: 'ремонтов',
    },
    {
      number: 279,
      textOne: 'счастливых',
      textTwo: 'заказчиков',
    },
    {
      number: 3,
      textOne: 'построенных',
      textTwo: 'коттеджа',
    },
    {
      number: 13,
      textOne: 'реализованных',
      textTwo: 'дизайн проектов',
    },
    {
      number: 186,
      textOne: 'сданных',
      textTwo: 'объекта',
    },
  ]

  return (
    <Container className={styles.mainContainer} id="about">
      <div className={styles.mainContent}>
        <div className={styles.border} />
        <div className={styles.mainText}>КОРОТКО О НАС</div>
        <div className={styles.box}>
          {aboutArr.map((about) => (
            <NumberAndText key={about} about={about} />
          ))}
        </div>
        <div className={styles.border} />
      </div>
    </Container>
  )
}

export default AboutUs
