import React from 'react'

import Tax from '../taxComponent/Tax'
import Header from '../headerComponent/header'
import AboutUs from '../aboutUs/AboutUs'
import OurWork from '../whatWeDo/OurWork'
import WriteUs from '../writeUsComponents/WriteUs'
import Review from '../reviewComponents/Rewiew'
import PrincipeOfWork from '../principeComponent/Principe'
import Portfolio from '../portfolioComponent/Portfolio'

const MainPage = () => (
  <>
    <Header />
    <AboutUs />
    <OurWork />
    <PrincipeOfWork />
    <Tax />
    <Portfolio />
    <Review />
    <WriteUs />
  </>
)

export default MainPage
