import React, { useState } from 'react'
import { Container } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import image1 from '../../assets/images/portfolio/portfolio1.jpg'
import image2 from '../../assets/images/portfolio/portfolio1a.jpg'
import image3 from '../../assets/images/portfolio/portfolio1b.jpg'
import image4 from '../../assets/images/portfolio/portfolio1c.jpg'
import image5 from '../../assets/images/portfolio/portfolio2.jpg'
import image6 from '../../assets/images/portfolio/portfolio2a.jpg'
import image7 from '../../assets/images/portfolio/portfolio2b.jpg'
import image8 from '../../assets/images/portfolio/portfolio3b.jpg'
import image9 from '../../assets/images/portfolio/portfolio3.jpg'
import image10 from '../../assets/images/portfolio/portfolio3a.jpg'
import image11 from '../../assets/images/portfolio/portfolio3d.jpg'
import image12 from '../../assets/images/portfolio/portfolio3e.jpg'

import styles from './styles.module.scss'

const Portfolio = () => {
  const [step, setStep] = useState(1)

  const mock = [
    {
      step: 1,
      imageOne: image1,
      imageTwo: image2,
      imageThree: image3,
      imageFour: image4,
      title: 'Загородный дом в с. Новобессергеновка',
      typeHome: 'Загородный дом',
      typeS: '230 м.кв.',
      typeScope: '11 месяцев',
      typeTask: 'Ремонт под ключ',
      typeQa: [
        'Большое количество молдингов.',
        'Стены окрашены глубокоматовой , стойкой к истеранию, моющейся краской.',
        'В изголовье кровати реализованы текстильные панели с зеркальными вставками. Напольное покрытие массив дерева.',
        'Многоуровневый потолок с декоративными элементами из гипса.',
      ],
    },
    {
      step: 2,
      imageOne: image5,
      imageTwo: image6,
      imageThree: image7,
      imageFour: image8,
      title: '3 комнатная квартира на Ленина',
      typeHome: 'Квартира',
      typeS: '85 м.кв.',
      typeScope: '7 месяцев',
      typeTask: 'Ремонт под ключ',
      typeQa: [
        'Многоуровневый потолок из гипсокартона с нишами для штор и скрытой подсветкой.',
        'Стены окрашены глубокоматовой, постельного оттенка краской.',
        'В изголовье кровати выполнены деревянные панели с зеркальными вставками с скрытой подсветкой.',
        'На полу уложена инженерная доска высокого качества..',
      ],
    },
    {
      step: 3,
      imageOne: image12,
      imageTwo: image11,
      imageThree: image10,
      imageFour: image9,
      title: '3 комнатная квартира на 1-м новом',
      typeHome: 'Квартира',
      typeS: '75 м.кв.',
      typeScope: '6 месяцев',
      typeTask: 'Ремонт под ключ',
      typeQa: [
        'Двухуровневый пототлок с фигурными гипсовыми элементами, граничит с рваными балками из дерева.',
        'Смонтировано трековое освещение.',
        'Трэковый светильник помогают индивидуально подобрать световой дизайн.',
        'Классические двери, брутальные железные рамы удачно гармонируют в интерьере.',
      ],
    },
  ]

  return (
    <div className={styles.main} id="projects">
      <Container>
        <h2 className={styles.title}>
          Наши последние
          <br />
          проекты
        </h2>
        {mock.map((item) => {
          return (
            step === item.step && (
              <>
                <div className={styles.main__bocks}>
                  <div>
                    <div className={styles.main__imageMain}>
                      <img
                        src={item.imageOne}
                        alt="Наши проекты MDM house, ремонт в таганроге"
                        className={styles.main__imageSize}
                      />
                    </div>
                    <div className={styles.main__imageInner}>
                      <img
                        src={item.imageTwo}
                        alt="Наши проекты MDM house, ремонт в таганроге"
                        className={styles.main__imageSizeBot}
                      />
                      <img
                        src={item.imageThree}
                        alt="Наши проекты MDM house, ремонт в таганроге"
                        className={styles.main__imageSizeBot}
                      />
                      <img
                        src={item.imageFour}
                        alt="Наши проекты MDM house, ремонт в таганроге"
                        className={styles.main__imageSizeBot}
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className={styles.main__textBlockHead}>{item.title}</h3>
                    <div>
                      <div className={styles.main__boxDesc}>
                        <p className={styles.main__textTitle}>ТИП ЖИЛЬЯ:</p>
                        <p className={styles.main__boxText}>{item.typeHome}</p>
                      </div>
                      <div className={styles.main__boxDesc}>
                        <p className={styles.main__textTitle}>ПЛОЩАДЬ:</p>
                        <p className={styles.main__boxText}>{item.typeS}</p>
                      </div>
                      <div className={styles.main__boxDesc}>
                        <p className={styles.main__textTitle}>
                          СРОК ВЫПОЛНЕНИЯ:
                        </p>
                        <p className={styles.main__boxText}>{item.typeScope}</p>
                      </div>
                      <div className={styles.main__boxDesc}>
                        <p className={styles.main__textTitle}>ЗАДАЧА:</p>
                        <p className={styles.main__boxText}>{item.typeTask}</p>
                      </div>
                      <div className={styles.main__boxDesc}>
                        <p className={styles.main__textTitle}>ОСОБЕННОСТЬ:</p>
                        <p className={styles.main__boxText}>{item.typeQa}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.main__blockNumber}>
                  <div
                    onClick={() =>
                      item.step === 1 ? {} : setStep(item.step - 1)
                    }
                  >
                    <ExpandMoreIcon className={styles.main__less} />
                  </div>
                  <div>
                    <p className={styles.main__textNumber}>0{item.step}/03</p>
                  </div>
                  <div
                    onClick={() =>
                      item.step === 3 ? {} : setStep(item.step + 1)
                    }
                  >
                    <ExpandMoreIcon className={styles.main__more} />
                  </div>
                </div>
              </>
            )
          )
        })}
      </Container>
    </div>
  )
}

export default Portfolio
