import React, { useState } from 'react'
import { Avatar, Container } from '@material-ui/core'

import customerOne from '../../assets/images/CustomerOne.jpeg'
import customerTwo from '../../assets/images/CustomerThree.jpeg'
import customerThree from '../../assets/images/CustomerTwo.jpeg'

import styles from './styles.module.scss'

const Review = () => {
  const [showReview, setShowReview] = useState(false)
  // const handleShowReview = () => {
  //     setShowReview(!showReview)
  // }

  const mock = [
    {
      avatar: customerThree,
      title: 'Алексей',
      date: '30 Июля 2020',
      text:
        'Хорошая бригада, справились со своей задачей на отлично, были разногласия, но мы их хорошо отрегулировали.\n' +
        ' Что хочу выделить от себя, так это то, что мне не приходилось срываться посреди дня и ехать решать какой-то вопрос на объект,\n' +
        ' либо заниматься закупкой материала. Все было на их плечах и прошло хорошо.\n' +
        'Честные, отзывчивые и не конфликтные, как многие на сегодняшний день.',
    },
    {
      avatar: customerTwo,
      title: 'Елена',
      date: '23 Марта 2020',
      text:
        'Спасибо мальчикам за хороший ремонт несмотря на мой скромный бюджет.\n' +
        'Было много интересных предложений с их стороны, чтобы сделать мой ремонт дешевле, но при этом качественно, как я и хотела.',
    },
    {
      avatar: customerOne,
      title: 'Андрей',
      date: '15 Февраля 2019',
      text:
        'Заказывали ремонт всей квартиры у ребят из mdm\n' +
        'house, остались довольны.\n' +
        'Работу выполнили не только качественно, но и были очень внимательны и\n' +
        'отзывчивы к нам. И по цене вышло очень даже приемлемо, так что советую за\n' +
        'ремонтом обращаться в эту компанию.',
    },
  ]

  const renderComment = () =>
    mock.map((item) => (
      <div
        className={styles.main__box}
        key={item.avatar + item.title + item.date + item.text}
      >
        <div className={styles.main__boxCard}>
          <div className={styles.main__avatar}>
            <div className={styles.main__boxImg}>
              <Avatar
                alt={`Наш заказчик ${item.title} MDM house, ремонт в таганроге`}
                src={item.avatar}
              />
              <div className={styles.main__textTitle}>
                <span className={styles.main__name}>{item.title}</span>
                <br />
                <span className={styles.main__date}>{item.date}</span>
              </div>
            </div>
            <div>
              <p className={styles.main__text}>{item.text}</p>
            </div>
          </div>
        </div>
        <div className={styles.main__border}>
          <div className={styles.borderCard} />
        </div>
      </div>
    ))

  return (
    <Container className={styles.reviewCont} id="feedback">
      <div>
        <div className={styles.border} />
        <div className={styles.mainText}>ОТЗЫВЫ КЛИЕНТОВ</div>
        <div className={styles.main}>
          {renderComment()}
          {showReview && renderComment()}
        </div>
        {/*{!showReview && <div className={styles.main__boxButton}>*/}
        {/*    <button*/}
        {/*        className={styles.main__button}*/}
        {/*        onClick={() => {*/}
        {/*            handleShowReview()*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        ВСЕ ОТЗЫВЫ*/}
        {/*    </button>*/}
        {/*</div>}*/}
      </div>
    </Container>
  )
}

export default Review
