import React from 'react'

import Logo from '../../assets/images/mdm_word.png'

import styles from './styles.module.scss'
import { Link } from 'gatsby'

const Header = () => (
  <header className={styles.main}>
    <div className={styles.main__container}>
      <div className={styles.main__container__content}>
        <div className={styles.main__container__content__bottom}>
          <img
            src={Logo}
            alt="MDM house, ремонт в таганроге ремонт, квартир под ключ"
            loading="eager"
          />
          <div className={styles.main__container__textLogo}>
            <span>
              КАЧЕСТВЕННЫЙ ПРЕМИАЛЬНЫЙ
              <br />
              <h1>РЕМОНТ КВАРТИР</h1> ТОЧНО В СРОК
            </span>
            <div className={styles.main__container__buttonHead}>
              <Link to="/#contact" className={styles.main__container__button}>
                СВЯЗАТЬСЯ
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main__container__line}>
        <div className={styles.main__container__border} />
        <div className={styles.main__container__circle} />
      </div>
      <div className={styles.main__container__circleFour}/>
    </div>
  </header>
)

export default Header
