import React from 'react'

import SEO from '../components/seo'
import MainPage from '../components/mainComponents/MainPage'

import avatar from '../assets/images/avatar.png'

const IndexPage = () => {
  return (
    <>
      <SEO
        title="MDM house | Ремонт квартир в Таганроге"
        description="MDM house - Ремонт квартир под ключ в Таганроге и Ростове-на-Дону. Ремонт под ключ и в новостройке. Делаем ремонт профессионально и недорого."
        image={avatar}
        htmlAttributes={{
          lang: 'ru',
        }}
        url="https://mdm-remont.ru"
        keywords={[
          'ремонт квартир',
          'ремонт квартир под ключ',
          'отделка квартира',
          'ремонт под ключ',
          'ремонт квартир в таганроге',
        ]}
      />
      <MainPage />
    </>
  )
}

export default IndexPage
