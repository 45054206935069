import React from 'react'
import { Container } from '@material-ui/core'

import styles from './styles.module.scss'
import { Link } from 'gatsby'

const Tax = () => {
  const cardCost = [
    {
      title: 'ЭКОНОМ',
      prise: 'от 3000 руб/м.кв',
      list: [
        'шпаклевка без выравнивания плоскости и уровня',
        'поклейка обоев',
        'бюджетные напольные покрытия',
        'замена чистовой электрики/сантехники',
        'натяжной потолок',
        'обшивка стен в сан узле ПВХ панелями(укладка плитки на пол)',
      ],
    },
    {
      title: 'СТАНДАРТ',
      prise: 'от 6000 руб/м.кв',
      list: [
        'выравнивание стен по уровню и плоскости с частичной  геометрией (под обои)',
        'поклейка обоев',
        'натяжной потолок',
        'выравнивание пола (стяжка)',
        'напольные покрытия среднего класса',
        'черновая электрика по типовому проекту /сантехника',
        'укладка керамической плитки на стены',
      ],
    },
    {
      title: 'ЛЮКС',
      prise: 'от 9500 руб/м.кв',
      list: [
        'Выравнивание стен по уровню и плоскости с полной геометрией',
        'подготовка стен под тонкие обои, покраску, фрески',
        'потолок из ГКЛ',
        'подготовка потолка под покраску/покраска',
        'выравнивание пола (подготовка под любое напольное покрытие)',
        'напольные покрытия премиального качества',
        'полная замена инженерных систем',
        'укладка керамогранита',
      ],
    },
  ]

  return (
    <Container className={styles.containerTax} id="cost">
      <div className={styles.mainContent}>
        <div className={styles.border} />
        <div className={styles.mainText}>НАШИ ТАРИФЫ</div>
        <div className={styles.main}>
          {cardCost.map((item) => (
            <div
              key={item.list + item.prise + item.title}
              className={styles.main__allBox}
            >
              <div>
                <div className={styles.main__title}>{item.title}</div>
                <div className={styles.main__prise}>{item.prise}</div>
                <div className={styles.main__list}>
                  <ul>
                    {item.list.map((elem) => (
                      <li key={elem}>{elem}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={styles.main__boxButton}>
                <Link to="/#contact" className={styles.main__button}>
                  ЗАКАЗАТЬ
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.border} />
      </div>
    </Container>
  )
}

export default Tax
