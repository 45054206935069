import React from 'react'
import Container from '@material-ui/core/Container'

import styles from './styles.module.scss'

const RenderCard = ({
  item: { styleAll, styleName, textOne, textTwo, textThree },
}) => (
  <div className={styleAll}>
    <div className={styleName} />
    {textOne && (
      <div className={styles.box__text}>
        <h2>{textOne}</h2>
        <br />
        <h2>{textTwo}</h2>
        <br />
        <h2>{textThree}</h2>
      </div>
    )}
  </div>
)

const OurWork = () => {
  const cardsArr = [
    {
      textOne: 'РЕМОНТ',
      textTwo: 'КВАРТИР',
      textThree: 'ПОД КЛЮЧ',
      styleName: styles.box__one,
      styleAll: styles.box__innerOne,
    },
    {
      styleName: styles.box__two,
      styleAll: styles.box__innerTwo,
    },
    {
      textOne: 'СТРОИТЕЛЬСТВО',
      textTwo: 'КОТТЕДЖЕЙ',
      textThree: 'ПОД КЛЮЧ',
      styleName: styles.box__three,
      styleAll: styles.box__innerThree,
    },
    {
      styleName: styles.box__four,
      styleAll: styles.box__innerFour,
    },
    {
      textOne: 'РЕМОНТ',
      textTwo: 'ОФИСОВ И',
      textThree: 'МАГАЗИНОВ',
      styleName: styles.box__five,
      styleAll: styles.box__innerFive,
    },
    {
      styleName: styles.box__six,
      styleAll: styles.box__innerSix,
    },
    {
      textOne: 'ДИЗАЙН',
      textTwo: 'ПРОЕКТЫ',
      textThree: 'ПОМЕЩЕНИЙ',
      styleName: styles.box__seven,
      styleAll: styles.box__innerSeven,
    },
  ]

  return (
    <Container className={styles.mainContainer}>
      <div className={styles.mainContent}>
        <div className={styles.box}>
          {cardsArr.map((item) => (
            <RenderCard key={item} item={item} />
          ))}
        </div>
      </div>
      <div/>
    </Container>
  )
}

export default OurWork
