import React, { useState } from 'react'
import { Container } from '@material-ui/core'

import check from '../../assets/images/checkmark-outline.png'

import styles from './styles.module.scss'

const PrincipeOfWork = () => {
  const [step, setStep] = useState(1)
  const items = [
    {
      step: 1,
      title: 'ПРИНЦИП',
      description: 'С вашим минимальным участием',
      content: [
        'Оптимальное планирование всех строительных процессов.',
        'Организация доставки, подъем материала на этаж.',
        'Фото, видео отчет о проделанной работе.',
        'Закупка чернового материала и необходимых расходников.',
      ],
    },
    {
      step: 2,
      title: 'ПРИНЦИП',
      description: 'Цены',
      content: [
        'Цена за услуги не меняется после заключения договора',
        'Закупка материалов с нашими скидками',
        'С нами вы экономите т.к. мы делаем ремонт с первого раза',
        'Цена напрямую соответствует качеству наших работ',
      ],
    },
    {
      step: 3,
      title: 'ПРИНЦИП',
      description: 'Качество',
      content: [
        'Предоставляем гарантию на все виды работ',
        'Уделяем внимание обучению и регулярно повышаем квалификацию сотрудников',
        'Работаем качественными материалами проверенных компаний',
        'Предоставляем расширенный спектр услуг от выполнения дизайн проекта до изготовления мебели',
      ],
    },
    {
      step: 4,
      title: 'ПРИНЦИП',
      description: 'Бонусы',
      content: [
        'Бесплатное предоставление услуг прораба и технадзор вашего объекта',
        'Бесплатный выезд замерщика',
        'При заказе ремонта под ключ, технический дизайн в подарок.',
        'По окончанию работ вы получаете гарантированный подарок от нашей компании',
      ],
    },
  ]

  return (
    <div className={styles.main}>
      <div className={styles.main__background} />
      <Container className={styles.principleCont}>
        <h2 className={styles.main__titleText}>
          4 Отличительных принципа,
          <br />
          по которым мы работаем
        </h2>
        <div className={styles.main__block}>
          {items.map((item) => (
            <div
              className={styles.main__blocks}
              onClick={() => setStep(item.step)}
              key={item.step + item.description + item.title}
            >
              <div
                className={
                  step === item.step
                    ? styles.main__activeNumber
                    : styles.main__number
                }
              >
                0{item.step}
              </div>
              {step === item.step && (
                <>
                  {/*<div>*/}
                  {/*    <span className={styles.main__textPrinc}>{item.title}</span>*/}
                  {/*</div>*/}

                  <div className={styles.main__textTit}>
                    <span className={styles.main__textDescrip}>
                      {item.description}
                    </span>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <div className={styles.main__blockText}>
          <div className={styles.main__box}>
            {items[step - 1].content.map((item) => (
              <div className={styles.main__boxInner} key={item}>
                <img src={check} alt="sclnkcs" className={styles.main__img} />
                <span className={styles.main__textBox}>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PrincipeOfWork
