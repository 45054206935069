import React, { Component } from 'react'

import styles from './styles.module.scss'
import { Container } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CallIcon from '@material-ui/icons/Call'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { navigate } from 'gatsby'
import ym from 'react-yandex-metrika'

class WriteUs extends Component {
  constructor(props) {
    super(props)
    this.WriteUs = React.createRef()
    this.state = {
      value: { name: '', phone: '' },
      valid: { name: true, phone: true },
    }
  }

  encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&')
  }

  handleChange(value, name) {
    const approvedFieldStatus = (pattern, keys) => {
      this.setState((prevState) => ({
        ...prevState,
        value: { ...prevState.value, [keys]: value },
      }))
      if (pattern)
        this.setState((prevState) => ({
          ...prevState,
          valid: { ...prevState.valid, [keys]: false },
        }))
      else {
        this.setState((prevState) => ({
          ...prevState,
          valid: { ...prevState.valid, [keys]: true },
        }))
      }
    }
    switch (name) {
      case 'phone':
        approvedFieldStatus(
          value.replace(/[-\s]/g, '').match(/^((\+7|7|8)+([0-9]){10})$/gm),
          'phone',
        )
        return null
      case 'name':
        approvedFieldStatus(value.trim().length > 1, 'name')
        return null
      default:
        return null
    }
  }

  handleSubmit = () => {
    const form = this.WriteUs.current
    const { valid, value } = this.state

    if (!(valid.name || valid.phone)) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: this.encode({
          'form-name': form.getAttribute('name'),
          ...value,
        }),
      })
        .then(() => {
          navigate(form.getAttribute('action'))
        })
        .catch((error) => {
          console.log('====================================')
          console.log(`error in submiting the form data:${error}`)
          console.log('====================================')
        })
    }
  }

  render() {
    const { valid, value } = this.state
    const isFormValid = valid.name || valid.phone
    return (
      <Container className={styles.containerFeedback} id="contact">
        <div className={styles.main}>
          <form
            name="contact"
            method="post"
            action="/success/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            ref={this.WriteUs}
          >
            <div className={styles.main__boxFirst}>
              <div className={styles.main__boxForm}>
                <div className={styles.main__boxFlex}>
                  <div className={styles.main__title}>Остались вопросы?</div>
                  <div className={styles.main__textForm}>
                    Заполните форму, и мы свяжемся
                    <br />с Вами в ближайшее время
                  </div>
                  <div>
                    <form
                      className={styles.main__textInput}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="name"
                        label="Ваше Имя"
                        value={value.name}
                        onChange={(e) =>
                          this.handleChange(e.target.value, 'name')
                        }
                        className={`${styles.main__form__firstName} ${
                          value.name === ''
                            ? styles.main__textInput
                            : !valid.name && styles.main__form__firstName__valid
                        }`}
                      />
                    </form>
                  </div>
                  <div>
                    <form
                      className={styles.main__textInput}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="phone"
                        label="Номер Телефона"
                        value={value.phone}
                        onChange={(e) =>
                          this.handleChange(e.target.value, 'phone')
                        }
                        className={`${styles.main__form__phone} ${
                          value.phone === ''
                            ? styles.main__textInput
                            : !valid.phone
                            ? styles.main__form__phone__valid
                            : styles.main__form__phone__noValid
                        }`}
                      />
                    </form>
                  </div>
                </div>
                <div className={styles.main__buttonFlex}>
                  <div className={styles.main__boxButton}>
                    <button
                      onClick={() => this.handleSubmit()}
                      disabled={isFormValid}
                      className={`${styles.main__form__button} ${
                        isFormValid && styles.main__form__button__noValid
                      }`}
                    >
                      ОТПРАВИТЬ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className={styles.main__boxTwo}>
            <div className={styles.main__boxBack}>
              <div className={styles.main__boxSvg}>
                <a href='tel: 89885359001' onClick={() => {
                  ym('reachGoal', 'Zvonok_niz_stranici')
                }}>
                  <CallIcon className={styles.main__svg} />
                  <span>+ 7 (988) 535 90 01</span>
                </a>
              </div>
              <div className={styles.main__boxSvg}>
                <LocationOnIcon className={styles.main__svg} />
                <span>Таганрог, Россия</span>
              </div>
              <div className={styles.main__logo}>
                MDM
                <br />
                HOUSE
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default WriteUs
